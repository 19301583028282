// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from '@remix-run/css-bundle';
import { json, redirect } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useNavigate } from '@remix-run/react';
import { ErrorOutline, InfoOutlined, TaskAltOutlined, WarningAmber } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material';
import { createHead } from 'remix-island';
import APIService from './services/ApiService';
import { checkIsAuthenticated, destroySession, getAuthToken, getSession, refreshSessionExpiration } from './sessions';
import font from './styles/font.css';
import otpstylesheet from './styles/otp.css';
import tailwindstylesheet from './styles/tailwind.css';
import { GeneralContext } from './utils/GeneralContext';
import { ConvertCurrencyProvider } from './hooks/useConvertCurrency';
import CompanyServices from './services/company-services/company-services';
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: cssBundleHref
}, {
  rel: 'stylesheet',
  href: tailwindstylesheet
}, {
  rel: 'stylesheet',
  href: font
}, {
  rel: 'stylesheet',
  href: otpstylesheet
}] : [{
  rel: 'stylesheet',
  href: font
}, {
  rel: 'stylesheet',
  href: tailwindstylesheet
}, {
  rel: 'stylesheet',
  href: otpstylesheet
}])];
export const loader = async ({
  request
}) => {
  const baseApiUrl = process.env.BASE_API_URL;
  // Check is authenticated
  const isAuthenticated = await checkIsAuthenticated(request);
  const host = new URL(request.url).hostname;
  const name = '';
  const userInfo = {
    user: null,
    roles: [],
    permissions: [],
    resources: []
  };
  let authToken = '';
  const headers = {};
  const myCompanyInfo = {
    isError: false,
    type: 'success',
    message: '',
    data: {}
  };
  if (isAuthenticated) {
    const cookieHeader = await refreshSessionExpiration(request);
    if (cookieHeader) {
      headers['Set-Cookie'] = cookieHeader;
    }
    authToken = await getAuthToken(request);
    try {
      const apiService = new APIService(process.env.BASE_API_URL, authToken);
      try {
        const {
          permissions
        } = await apiService.GetRolePermissions();
        const userData = await apiService.GetUserInfo();
        const resources = await apiService.fetchResources();
        userInfo.user = userData || null;
        userInfo.roles = userData?.roles || [];
        userInfo.permissions = permissions || [];
        userInfo.resources = resources || [];
      } catch (error) {
        console.error('[loader]', error);
        if (!error) {
          // AuthToken is not valid
          const session = await getSession(request.headers.get('Cookie'));
          return redirect(`/auth/login`, {
            headers: {
              'Set-Cookie': await destroySession(session)
            }
          });
        }
      }
    } catch (error) {
      console.error('[loader]', error);
    }
    try {
      const apiCompanyService = new CompanyServices(baseApiUrl, authToken);
      const result = await apiCompanyService.getMyCompany();
      myCompanyInfo.data = result.data;
    } catch (error) {
      console.error('[loader]', error);
    }
  }
  return json({
    host,
    isAuthenticated,
    name,
    baseApiUrl,
    authToken,
    userInfo,
    myCompanyInfo: myCompanyInfo?.data || null
  }, {
    headers
  });
};
const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          height: '48px',
          alignItems: 'center'
        },
        action: {
          paddingTop: '0px'
        }
      },
      defaultProps: {
        iconMapping: {
          warning: <WarningAmber className="!text-white"></WarningAmber>,
          success: <TaskAltOutlined className="!text-white"></TaskAltOutlined>,
          error: <ErrorOutline className="!text-white"></ErrorOutline>,
          info: <InfoOutlined className="!text-white"></InfoOutlined>
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '30px',
          color: '#2B406A'
        }
      }
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: '30px',
          color: '#2B406A'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: '#2B406A',
          fontSize: '1.875rem',
          fontWeight: 'normal',
          fontFamily: 'inherit'
        },
        h4: {
          color: '#2B406A',
          fontSize: '1.625rem',
          fontWeight: '700',
          fontFamily: 'inherit'
        },
        h5: {
          color: '#2B406A',
          fontSize: '1.125rem',
          fontWeight: 'normal',
          fontFamily: 'inherit'
        },
        h6: {
          color: '#2B406A',
          fontSize: '1rem',
          fontWeight: 'normal',
          fontFamily: 'inherit'
        },
        body1: {
          color: '#1F1F1F',
          fontSize: '0.9rem',
          fontWeight: 'normal',
          fontFamily: 'inherit'
        },
        body2: {
          color: '#1F1F1F',
          fontSize: '1rem',
          fontWeight: 'normal',
          fontFamily: 'inherit'
        }
      }
    }
  }
});
export const Head = createHead(_c = () => <>
    <Meta />
    <Links />
  </>);
_c2 = Head;
export default function App() {
  _s();
  const data = useLoaderData() || {};
  const navigate = useNavigate();
  if (data?.userInfo == null || data.isAuthenticated === false) {
    navigate('/auth/login');
  }
  return <>
      <ThemeProvider theme={theme}>
        <GeneralContext.Provider value={{
        generalContextValue: data
      }}>
          <ConvertCurrencyProvider>
            <Outlet />
          </ConvertCurrencyProvider>
        </GeneralContext.Provider>
      </ThemeProvider>
      <ScrollRestoration />
      <Scripts />
      {data.host === 'localhost' && <LiveReload />}
      <Head />
    </>;
}
_s(App, "aIkTzrQQPvNTzaPKsMLC2VgTngI=", false, function () {
  return [useLoaderData, useNavigate];
});
_c3 = App;
var _c, _c2, _c3;
$RefreshReg$(_c, "Head$createHead");
$RefreshReg$(_c2, "Head");
$RefreshReg$(_c3, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;